import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Menu.css";
import { useTranslation } from "react-i18next";

const menuCategories = {
  "Ristorante": {
    "Le Bruschette": [
      {
        "name": "Aglio e Olio Evo",
        "price": "€2.90"
      },
      {
        "name": "Pomodoro e Basilico",
        "price": "€6.70"
      },
      {
        "name": "Pomodoro e Mozzarella",
        "price": "€7.70"
      },
      {
        "name": "Crema di carciofi",
        "price": "€7.90"
      },
      {
        "name": "Salmone Mozzarella",
        "price": "€8.90"
      },
      {
        "name": "Burrata e Pomodori Secchi",
        "price": "€7.90"
      }
    ],
    "La Nostra Pasta all'uovo Tirata a Mano": [
      {
        "name": "Gnocchi Pomodoro e Basilico",
        "price": "€12.80"
      },
      {
        "name": "Gnocchi al Gorgonzola",
        "price": "€13.90"
      },
      {
        "name": "Tonnarelli alle Vongole Veraci",
        "price": "€16.80"
      },
      {
        "name": "Tonnarelli alla carbonara con pepe rosa",
        "price": "€16.50"
      },
      {
        "name": "Tonnarelli cacio e pepe",
        "price": "€16.30"
      },
      {
        "name": "Fettuccine con pomodori datterini e basilico",
        "price": "€15.00"
      },
      {
        "name": "Fettuccine all’Alfredo",
        "price": "€15.90"
      },
      {
        "name": "Fettuccine al ragù",
        "price": "€16.80"
      },
      {
        "name": "Tagliolini al pesto fatto in casa con pinoli italiani e parmigiano 30 mesi",
        "price": "€16.50"
      },
      {
        "name": "Tagliolini calamari freschi e pomodorini",
        "price": "€16.50"
      },
      {
        "name": "Tagliolini salmone, fiori di zucca e pomodorini",
        "price": "€16.50"
      },
      {
        "name": "Strozzapreti acqua e farina all’amatriciana",
        "price": "€16.50"
      },
      {
        "name": "Cannelloni di vitella al forno al legna",
        "price": "€16.80"
      },
      {
        "name": "Lasagna al forno a legna",
        "price": "€16.80"
      },
      {
        "name": "Ravioli di ricotta e spinaci con burro e salvia",
        "price": "€16.80"
      },
      {
        "name": "Tortellini in brodo di cappone",
        "price": "€16.50"
      }
    ],
    "Le zuppe": [
      {
        "name": "Zuppa di verdure",
        "price": "€10.00"
      },
      {
        "name": "Maltagliati e Fagioli",
        "price": "€15.60"
      },
      {
        "name": "Zuppa di Legumi",
        "price": "€15.60"
      }
    ],
    "Le carni": [
      {
        "name": "Abbacchio cotto al forno a legna con patate",
        "price": "€28,50"
      },
      {
        "name": "Piccata di vitella al limone",
        "price": "€24,30"
      },
      {
        "name": "Saltimbocca di Vitella alla romana",
        "price": "€25,50"
      },
      {
        "name": "Controfiletto di vacca reggiana",
        "price": "€28,50"
      },
      {
        "name": "Tagliata di pollo",
        "price": "€16,50"
      },
      {
        "name": "Polpette di vitella al sugo",
        "price": "€18,50"
      }
    ],
    "Il Pesce": [
      {
        "name": "Sautè di Vongole Veraci",
        "price": "€19.50"
      },
      {
        "name": "Spigola con Pomodorini, Patate e Olive",
        "price": "€25.00"
      },
      {
        "name": "Calamari Freschi Pescati e Fritti",
        "price": "€25.00"
      }
    ],
    "I Fritti": [
      {
        "name": "Un Fiore di Zucca",
        "price": "€3.60"
      },
      {
        "name": "10 Olive Ascolane Riempite a Mano",
        "price": "€13.50"
      },
      {
        "name": "Patate Fritte Tagliate a Mano",
        "price": "€7.50"
      },
      {
        "name": "Calamari Freschi Pescati e Fritti",
        "price": "€25.00"
      }
    ],
    "Gli affettati": [
      {
        "name": "Prosciutto di Parma di Prima Qualità",
        "price": "€13.60"
      },
      {
        "name": "Prosciutto di Parma di Prima Qualità e Mozzarella di Bufala",
        "price": "€16.90"
      },
      {
        "name": "Bresaola e Rucola con scaglie di parmigiano 30 mesi",
        "price": "€15.50"
      }
    ],
    "Le verdure": [
      {
        "name": "Cicoria Ripassata",
        "price": "€7.00"
      },
      {
        "name": "Spinaci Ripassati Burro e Parmigiano 30 Mesi",
        "price": "€7.00"
      },
      {
        "name": "Parmigiana di Melanzane",
        "price": "€13.70"
      },
      {
        "name": "Zucchine romanesche al forno a legna",
        "price": "€13.70"
      },
      {
        "name": "Melanzane al forno a legna",
        "price": "€7.00"
      },
      {
        "name": "Carciofo alla Romana",
        "price": "€6.80"
      }
    ],
    "Le Insalate": [
      {
        "name": "Verde",
        "price": "€7.00"
      },
      {
        "name": "Mista",
        "price": "€8.00"
      },
      {
        "name": "Caprese con Mozzarella di Bufala",
        "price": "€13.60"
      },
      {
        "name": "Tonno e Cipolla rossa",
        "price": "€12.00"
      }
    ],
    "Patate Sotto la Cenere": [
      {
        "name": "Al Burro",
        "price": "€7.50"
      },
      {
        "name": "Asiago e Funghi",
        "price": "€13.50"
      },
      {
        "name": "Radicchio e Gorgonzola",
        "price": "€13.50"
      },
      {
        "name": "Speck e Provola",
        "price": "€13.50"
      },
      {
        "name": "Brie, Pomodorini e Prosciutto",
        "price": "€13.90"
      }
    ],
    "I Crostini": [
      {
        "name": "Alici di Prima Qualità e Mozzarella",
        "price": "€13.50"
      },
      {
        "name": "Prosciutto di Parma di Prima Qualità e Mozzarella",
        "price": "€13.50"
      }
    ],
    "I Nostri Dolci Artigianali": [
      {
        "name": "Tiramisù",
        "price": "€8.30"
      },
      {
        "name": "Cannolo Siciliano",
        "price": "€8.30"
      },
      {
        "name": "Cream Caramel",
        "price": "€8.30"
      },
      {
        "name": "Cheesecake",
        "price": "€8.30"
      },
      {
        "name": "Torta di Mele",
        "price": "€8.30"
      },
      {
        "name": "Sacher con Panna",
        "price": "€8.30"
      },
      {
        "name": "Coppa al Cioccolato Pan di Stelle",
        "price": "€8.30"
      },
      {
        "name": "Coppa al Cioccolato con Granella di Nocciole",
        "price": "€8.30"
      },
      {
        "name": "Crostata con Crema Pasticciera e Frutti di Bosco",
        "price": "€8.50"
      },
      {
        "name": "Crema al Limone con Savoiardi e Meringa Morbida",
        "price": "€8.30"
      },
      {
        "name": "Focaccia con la Nutella",
        "price": "€12.50"
      },
      {
        "name": "Gelato",
        "price": "€8.30"
      }
    ],
    "I Formaggi": [
      {
        "name": "Mozzarella di Bufala",
        "price": "€9.00"
      },
      {
        "name": "Parmigiano Reggiano 30 Mesi",
        "price": "€13.00"
      },
      {
        "name": "Burrata con Pomodori Secchi e Basilico",
        "price": "€13.50"
      },
      {
        "name": "Misto di Formaggi",
        "price": "€14.00"
      },
      {
        "name": "Scamorza, Prosciutto e Funghi Cotti nel Forno a Legna",
        "price": "€13.90"
      }
    ],
    "La Frutta": [
      {
        "name": "Ananas",
        "price": "€7.90"
      },
      {
        "name": "Macedonia",
        "price": "€7.90"
      },
      {
        "name": "Melone",
        "price": "€7.90"
      },
      {
        "name": "Frutti di Bosco",
        "price": "€8.50"
      },
      {
        "name": "Fragole con Panna",
        "price": "€7.90"
      }
    ],
    "La Caffetteria": [
      {
        "name": "Caffè Espresso 100% Arabica",
        "price": "€2.90"
      },
      {
        "name": "Thè",
        "price": "€2.90"
      },
      {
        "name": "Cappuccino",
        "price": "€4.00"
      },
      {
        "name": "Cioccolata Calda con Panna",
        "price": "€5.90"
      },
      {
        "name": "Pane per Persona",
        "price": "€2.20"
      }
    ]
  },
  "Pizzeria": {
    "Le Pizze": [
      {
        "name": "Margherita",
        "price": "€10.90"
      },
      {
        "name": "Marinara",
        "price": "€7.80"
      },
      {
        "name": "Salame Piccante",
        "price": "€13.90"
      },
      {
        "name": "Quattro Formaggi",
        "price": "€13.90"
      },
      {
        "name": "Salsiccia",
        "price": "€13.80"
      },
      {
        "name": "Patate e salsiccia",
        "price": "€13,90"
      },
      {
        "name": "Tonno e Cipolla",
        "price": "€13,90"
      },
      {
        "name": "Napoli con acciughe di prima qualità",
        "price": "€12,90"
      },
      {
        "name": "Funghi",
        "price": "€12,90"
      },
      {
        "name": "Funghi, pomodoro, burrata, prezzemolo",
        "price": "€13,90"
      },
      {
        "name": "Verdure miste",
        "price": "€13,80"
      },
      {
        "name": "Capricciosa",
        "price": "€13,90"
      },
      {
        "name": "Prosciutto di Parma di prima qualità",
        "price": "€13,90"
      },
      {
        "name": "Prosciutto e funghi",
        "price": "€13,90"
      },
      {
        "name": "Speck, provola e fiori di zucca",
        "price": "€13,80"
      },
      {
        "name": "Mozzarella, fiori di zucca, pachino, olive",
        "price": "€13,80"
      },
      {
        "name": "Bufala e Pomodorini",
        "price": "€13,90"
      },
      {
        "name": "Burrata con Pomodori Secchi e Basilico",
        "price": "€13,90"
      },
      {
        "name": "Fiori di zucca e alici",
        "price": "€13,80"
      },
      {
        "name": "Boscaiola, pomodoro, mozzarella, funghi e salsiccia",
        "price": "€13,90"
      },
      {
        "name": "Pizza crostino prosciutto cotto e mozzarella",
        "price": "€13,80"
      },
      {
        "name": "Focaccia olio evo e rosmarino",
        "price": "€6.00"
      }
    ],
    "I Calzoni": [
      {
        "name": "Prosciutto Cotto e Mozzarella",
        "price": "€13.80"
      },
      {
        "name": "Verdure, Pomodoro e Mozzarella",
        "price": "€13.80"
      },
      {
        "name": "Funghi, Pomodoro e Mozzarella",
        "price": "€13.80"
      },
      {
        "name": "Calzone Romano",
        "price": "€14.50"
      }
    ]
  },
  "Cantina": {
    "Le Bevande": [
      {
        "name": "Acqua Minerale Naturale o con Gas 0.75 lt",
        "price": "€5.00"
      },
      {
        "name": "Ginger Ale",
        "price": "€5.00"
      },
      {
        "name": "Lattine (Coca Cola - Zero - Sprite - Fanta - Chinotto - Lemon Tea - Peach Tea) 0.33 lt",
        "price": "€5.00"
      }
    ],
    "Vino Bianco": [
      {
        "name": "Vino Bianco ¼ It",
        "price": "€7.90"
      },
      {
        "name": "Vino Bianco 1/2 lt",
        "price": "€13.90"
      },
      {
        "name": "Vino Bianco 1 lt",
        "price": "€19.90"
      },
      {
        "name": "Pinot Grigio 0.375 lt",
        "price": "€16.50"
      },
      {
        "name": "Pinot Grigio 0.75 lt",
        "price": "€35.00"
      },
      {
        "name": "Prosecco 0.75 lt",
        "price": "€32.00"
      },
      {
        "name": "Falanghina 0.75 lt",
        "price": "€30.00"
      },
      {
        "name": "Sauvignon 0.75 lt",
        "price": "€31.00"
      },
      {
        "name": "Chardonnay 0.75 lt",
        "price": "€31.00"
      },
      {
        "name": "Gewurztraminer 0.75 lt",
        "price": "€31.00"
      },
      {
        "name": "Rosè 0.75 lt",
        "price": "€28.00"
      },
      {
        "name": "Moscato d'Asti 0.75 lt",
        "price": "€27.00"
      }
    ],
    "Vino Rosso": [
      {
        "name": "Vino Rosso 1/4 lt",
        "price": "€7.90"
      },
      {
        "name": "Vino Rosso 1/2 lt",
        "price": "€13.90"
      },
      {
        "name": "Vino Rosso 1 lt",
        "price": "€19.80"
      },
      {
        "name": "Chianti 0.375 lt",
        "price": "€19.50"
      },
      {
        "name": "Chianti 0.75 lt",
        "price": "€39.00"
      },
      {
        "name": "Dolcetto D'Alba 0.75 lt",
        "price": "€33.00"
      },
      {
        "name": "Merlot Bio 0.75 lt",
        "price": "€35.00"
      },
      {
        "name": "Barbera 0.75 lt",
        "price": "€36.00"
      },
      {
        "name": "Montepulciano Bio 0.75 lt",
        "price": "€39.00"
      },
      {
        "name": "Cabernet Sauvignon 0.75 lt",
        "price": "€33.00"
      },
      {
        "name": "Sangiovese Bio 0.75 lt",
        "price": "€35.00"
      },
      {
        "name": "Lambrusco 0.75 lt",
        "price": "€28.00"
      },
      {
        "name": "Valpolicella 0.75 lt",
        "price": "€39.00"
      },
      {
        "name": "Brunello di Montalcino 0.75 lt",
        "price": "€78.00"
      }
    ],
    "Calici di Vino": [
      {
        "name": "Bianco o Rosso della casa",
        "price": "€6.90"
      },
      {
        "name": "Sauvignon - Pinot Grigio - Chardonnay - Falanghina - Rosé",
        "price": "€8.00"
      },
      {
        "name": "Prosecco - Gewurztraminer",
        "price": "€9.00"
      },
      {
        "name": "Merlot - Cabernet- Sangiovese - Dolcetto D\"Alba",
        "price": "€9.50"
      },
      {
        "name": "Chianti classico",
        "price": "€10.00"
      },
      {
        "name": "Moscato d\"Asti",
        "price": "€7.90"
      },
      {
        "name": "Montepulciano - Valpolicella-Barbera",
        "price": "€9.50"
      },
      {
        "name": "Brunello di Montalcino",
        "price": "€24.00"
      }
    ],
    // Sezione Aperitivi e Cocktail
    "Aperitivi e Cocktail": [
      {
        "name": "Aperol Spritz",
        "price": "€12.50"
      },
      {
        "name": "Campari Spritz",
        "price": "€12.50"
      },
      {
        "name": "Limoncello",
        "price": "€12.50"
      },
      {
        "name": "Negroni",
        "price": "€12.50"
      },
      {
        "name": "Americano",
        "price": "€12.50"
      },
      {
        "name": "Bellini",
        "price": "€12,50"
      },
      {
        "name": "Cuba Libre",
        "price": "€12,50"
      },
      {
        "name": "Gin Tonic",
        "price": "€12,50"
      },
      {
        "name": "Vodka Tonic",
        "price": "€12,50"
      },
      {
        "name": "Mojito",
        "price": "€12,50"
      },
      {
        "name": "Sangria",
        "price": "€12,50"
      }
    ],
    // Sezione Birre alla Spina
    "Le Birre alla Spina": [
      {
        "name": "Peroni-Nastro Azzurro 0.33",
        "price": "€6,90"
      },
      {
        "name": "Messina Cristalli di sale 0.33",
        "price": "€6,90"
      },
      {
        "name": "Becks 0.33",
        "price": "€6,90"
      },
      {
        "name": "Heineken 0.33",
        "price": "€6,90"
      },
      {
        "name": "Ceres 0.33",
        "price": "€7,90"
      },
      {
        "name": "Moretti rossa 0.33",
        "price": "€6,90"
      },
      {
        "name": "Guinnes 0.33",
        "price": "€6,90"
      },
      {
        "name": "Heineken analcolica 0.33",
        "price": "€6,90"
      }
    ]
  }
}
;

const Menu = () => {
  const { t } = useTranslation();
  const ristoranteRef = useRef(null);
  const pizzeriaRef = useRef(null);
  const cantinaRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <div className="menu-page-background">
      <div className="menu-page-container">
        <div className="menu-scroll-container">
          <div ref={ristoranteRef} id="ristorante" className="section">
            <div className="section-title-placeholder"></div>
            <h1>{t("Ristorante")}</h1>
            {Object.keys(menuCategories.Ristorante).map((section) => (
              <div key={section} className="menu-section">
                <h2 className="menu-section-header">{t(section)}</h2>
                <ul>
                  {menuCategories.Ristorante[section].map((item) => (
                    <li key={item.name} className="menu-item">
                      <span className="menu-item-name">{t(item.name)}</span>
                      <span className="menu-item-price">{item.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div ref={pizzeriaRef} id="pizzeria" className="section">
            <div className="section-title-placeholder"></div>
            <h1>{t("Pizzeria")}</h1>
            {Object.keys(menuCategories.Pizzeria).map((section) => (
              <div key={section} className="menu-section">
                <h2 className="menu-section-header">{t(section)}</h2>
                <ul>
                  {menuCategories.Pizzeria[section].map((item) => (
                    <li key={item.name} className="menu-item">
                      <span className="menu-item-name">{t(item.name)}</span>
                      <span className="menu-item-price">{item.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div ref={cantinaRef} id="cantina" className="section">
            <div className="section-title-placeholder"></div>
            <h1>{t("Cantina")}</h1>
            {Object.keys(menuCategories.Cantina).map((section) => (
              <div key={section} className="menu-section">
                <h2 className="menu-section-header">{t(section)}</h2>
                <ul>
                  {menuCategories.Cantina[section].map((item) => (
                    <li key={item.name} className="menu-item">
                      <span className="menu-item-name">{t(item.name)}</span>
                      <span className="menu-item-price">{item.price}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          <div className="menu-footer">
            <p className="menu-note">
              <strong>{t("Nota")}:</strong> {t("Il servizio è del 12%")}.
            </p>
            <p className="menu-note">
              {t("Nei nostri piatti possono essere presenti allergeni")}.
            </p>
            <p className="menu-note">
              {t("Non utilizziamo ingredienti congelati")}.
            </p>
          </div>
        </div>
        <button className="modal-button" onClick={handleOpenModal}>{t('Allergeni')}</button>
        <div className="menu-categories-sidebar">
          <div
            className="menu-category-sidebar-item"
            onClick={() => handleScrollTo(ristoranteRef)}
          >
            <a>{t("Ristorante")}</a>
          </div>
          <div
            className="menu-category-sidebar-item"
            onClick={() => handleScrollTo(pizzeriaRef)}
          >
            <a>{t("Pizzeria")}</a>
          </div>
          <div
            className="menu-category-sidebar-item"
            onClick={() => handleScrollTo(cantinaRef)}
          >
            <a>{t("Cantina")}</a>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={handleOverlayClick}>
          <div className="modal-content">
            <span className="close-button" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>Allergeni</h2>
            <h3>Frutta a guscio e derivati</h3>
            <p>
              Tutti i prodotti che includono: mandorle, nocciole, noci comuni,
              noci di acagiù, noci pecan e del Brasile e Queensland, pistacchi
            </p>
            <h3>Glutine</h3>
            <p>
              Cereali, grano, segale, orzo, avena, farro, kamut, inclusi
              ibridati, derivati
            </p>
            <h3>Lupino e Derivati</h3>
            <p>
              Presente ormai in molti cibi vegani, sotto forma di arrosti,
              salamini, farine e similari che hanno come base questo legume,
              ricco di proteine
            </p>
            <h3>Molluschi e derivati</h3>
            <p>
              Canestrello, cannolicchio, capasanta, cuore, dattero di mare,
              fasolaro, garagolo, lumachino, cozza, murice, ostrica, patella,
              tartufo di mare, tellina e vongola etc.
            </p>
            <h3>Pesce e derivati</h3>
            <p>
              Inclusi i derivati, cioè tutti quei prodotti alimentari che si
              compongono di pesce, anche se in piccole percentuali
            </p>
            <h3>Sedano e derivati</h3>
            <p>
              Presente in pezzi ma pure all'interno di preparati per zuppe,
              salse e concentrati vegetali
            </p>
            <h3>Semi di sesamo e derivati</h3>
            <p>
              Oltre ai semi interi usati per il pane, possiamo trovare tracce in
              alcuni tipi di farine
            </p>
            <h3>Senape e derivati</h3>
            <p>
              Si può trovare nelle salse e nei condimenti, specie nella mostarda
            </p>
            <h3>Soia e derivati</h3>
            <p>Latte, tofu, spaghetti, etc.</p>
            <h3>Uova e derivati</h3>
            <p>
              Tutti i prodotti composti con uova, anche in parte minima. Tra le
              più comuni
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Menu;
