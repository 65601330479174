import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import "./Home.css";
import Gallery from "../../Components/Gallery/Gallery";
import Image from "../../Components/Image+txt/Image";

function Home() {
  const { t } = useTranslation();
  const pastaImages = [
    "/imgs/tipa_pasta_new.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2023/01/DSC6572.jpeg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC6622.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC6612.jpg",
  ];

  const platesImages1 = [
    "https://labruschettaroma.com/wp-content/uploads/2023/01/DSC7339.jpeg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7277.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7177.jpg",
    "/imgs/bar_new.jpg",
  ];

  const platesImages2 = [
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7425.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7409.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7094.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC6931.jpg",
  ];

  const pizzaImages = [
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7044.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7374.jpg",
    "/imgs/pizza_acciughe_new.jpg",
    "https://labruschettaroma.com/wp-content/uploads/2022/12/DSC7432.jpg",
  ];

  const renderTextWithLink = (text) => {
    const linkText = t("ristorante");
    const parts = text.split(linkText);

    return (
      <>
        {parts[0]}
        <Link to="/menu" className="text-link">
          {linkText}
        </Link>
        {parts[1]}
      </>
    );
  };

  const texts = [
    t(
      "Ogni mattina, il nostro laboratorio si attiva per regalare un'esperienza visiva eccezionale che potrai gustare passeggiando su via Sardegna in maniera totalmente gratuita."
    ),
    t(
      "Utilizziamo ingredienti freschi, selezionandoli attentamente e lavorandoli con dedizione e amore, proprio come una volta."
    ),
    renderTextWithLink(
      t(
        "Successivamente la pasta fatta a mano viene trasformata per regalare sensazioni culinarie eccezionali e autentiche in uno dei piatti del nostro ristorante"
      )
    ),
  ];

  const [heroInViewRef, heroInView] = useInView({
    threshold: 0.5,
  });

  const [ctaInViewRef, ctaInView] = useInView({
    threshold: 0.5,
  });

  const [expanded, setExpanded] = useState(false);
  const [ctaVisible, setCtaVisible] = useState(true);

  const handleExpand = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    setCtaVisible(heroInView);
  }, [heroInView]);

  return (
    <div className="home-container">
      <div className="hero-section" ref={heroInViewRef}>
        <video
          alt="Background Video"
          className="hero-video"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src="/imgs/pizzaiolo.mp4" type="video/mp4" />
          Il tuo browser non supporta il tag video.
        </video>

        <div className="mask"></div>
        <div className={`hero-content ${heroInView ? "in-view" : ""}`}>
          <div>
            <h1 className={`${heroInView ? "in-view" : ""}`}>
              LA BRUSCHETTA E...
            </h1>
            <p className={`${heroInView ? "in-view" : ""}`}>
              {t("Dal 1928")}
              <br />
              {t("LA TRADIZIONE DI PIATTI SEMPLICI E FRESCHI")}
              <br />
              {t("TRAMANDATI DI PADRE IN FIGLIO")}
            </p>
            {heroInView && (
              <Link
                className={`cta-button ${ctaInView ? "in-view" : ""}`}
                to="/book"
              >
                {t("Prenota ora")}
              </Link>
            )}
          </div>
          <div className="social-icons-home">
            <a href="https://www.facebook.com/labruschetta.lab">
              <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/labruschetta.lab">
              <i className="bi bi-instagram"></i>
            </a>
            <a href="https://www.tripadvisor.it/Restaurant_Review-g187791-d1217436-Reviews-La_Bruschetta_E-Rome_Lazio.html">
              <i className="fa fa-tripadvisor"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="gallery-section">
        <Gallery
          title={t("LA PASTA COME UNA VOLTA...")}
          images={pastaImages}
          descr={t(
            "Preparata a mano con metodo tradizionale per un'esperienza unica e autentica."
          )}
          texts={texts}
        />
      </div>

      <div className="business-section">
        <div className={`business-content ${expanded ? "expanded" : ""}`}>
          <img src="/imgs/bg-aziende.jpg" alt="Business" />
          {!expanded && (
            <h2 onClick={handleExpand}>{t("Sei un lavoratore?")}</h2>
          )}
          {expanded && (
            <>
              <h2 onClick={handleExpand}>{t("COLLABORA CON NOI")}</h2>
              <p>
                {t(
                  "Riserva il tuo tavolo e ottieni un prezzo dedicato esclusivamente al tuo team."
                )}
              </p>
              <a
                className="contattaci-business-button"
                href="/collaborate-with-us"
              >
                {t("Contattaci")}
              </a>
            </>
          )}
        </div>
      </div>

      <div className="img-component">
        <Image
          title={t("IL RISTORANTE")}
          images={platesImages1}
          description={t(
            "La passione e la dedizione per la cucina hanno reso “La Bruschetta e...” un ristorante celebre non solo per la qualità ma anche per quel senso d'italianità unico e inimitabile. Aldo e poi suo figlio Raffaele hanno mantenuto alta la tradizione di nonno Andrea, selezionando con cura ingredienti di qualità e ampliando il menù con piatti della tradizione e una vasta scelta di pietanze per tutti i gusti."
          )}
          textPosition={"left"}
        />
      </div>

      <div className="img-component">
        <Image
          title={t("I PIATTI GASTRONOMICI")}
          images={platesImages2}
          description={t(
            "Da oltre 25 anni, Raffaele gestisce con passione e cura il ristorante-pizzeria “La Bruschetta e...”, rendendolo punto di riferimento per tutta la zona (e non solo). Nel locale si fondono alla perfezione tradizione storica di famiglia e ricerca di materie prime di altissima qualità con lo scopo di soddisfare le esigenze di tutti i palati attraverso sensazioni autentiche e esperienze inimitabili."
          )}
          textPosition={"right"}
        />
      </div>

      <div className="img-component">
        <Image
          title={t("LA PIZZA​")}
          images={pizzaImages}
          description={t(
            "La pizza è un grande classico della tradizione, un piatto che rappresenta l'Italia nel mondo. L'impasto lievitato naturalmente e la cottura nel forno a legna permettono una qualità massima e un sapore autentico. Gli ospiti della pizzeria non sono più semplici clienti ma diventano veri e propri creatori della loro pizza godendo della più completa libertà e avendo la possibilità di utilizzare gli ingredienti come preferiscono."
          )}
          textPosition={"left"}
        />
      </div>

      {!ctaVisible && (
        <Link
          className={`cta-button-fixed ${ctaVisible ? "" : "hidden"}`}
          to="/book"
        >
          {t("Prenota ora")}
        </Link>
      )}
    </div>
  );
}

export default Home;
